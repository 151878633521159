<template>
  <p-image-dragger ref="dragger" :value="startValue">
    <template #front>
      <p-image :src="props.frontSrc" :on-load="imageLoaded" alt="Front layer example image in image dragger" />
    </template>
    <template #back>
      <p-image :src="props.backSrc" :on-load="imageLoaded" alt="Back layer example image in image dragger" />
    </template>
    <template v-if="props.thirdLayerSrc" #thirdLayer>
      <p-image :src="props.thirdLayerSrc" :on-load="imageLoaded" alt="Background image in image dragger" />
    </template>
  </p-image-dragger>
</template>

<script setup lang="ts">
import { ref } from "vue";

const startValue = ref(100);
const endValue = ref(50);
const duration = ref(2000);
const dragger = ref();
const imagesLoaded = ref(0);

interface Props {
  frontSrc: string;
  backSrc: string;
  thirdLayerSrc?: string;
}

const props = defineProps<Props>();

const imageLoaded = () => {
  imagesLoaded.value += 1;
  if (imagesLoaded.value < 2) return;

  if (!dragger.value) return;
  dragger.value.transition(startValue.value, endValue.value, duration.value);
};
</script>
