<template>
  <ColorTile :onClick="select" :selected="isSelected(null)" class="border-2" :color="null">
    <div class="bg-white flex justify-center items-center !w-full !h-full">
      <DoNotEnterIcon />
    </div>
  </ColorTile>
  <ColorTile :selected="nativeColorPickerSelected" :onClick="select">
    <NativeColorPicker
      v-on:color-picker-change-complete="select"
      v-on:color-picker-changed="setCurrentColor"
    ></NativeColorPicker>
  </ColorTile>
  <ColorTile class="border-2" color="#ffffff" :onClick="select" :selected="isSelected('#ffffff')">
    <div class="!w-full !h-full bg-white" />
  </ColorTile>
  <ColorTile v-for="color in props.colors" :color="color" :onClick="select" :selected="isSelected(color)">
    <div class="!w-full !h-full" :style="{ backgroundColor: color }" />
  </ColorTile>
</template>

<script setup lang="ts">
import ColorTile from "../prism/color_tile.vue";
import NativeColorPicker from "../prism/native_color_picker.vue";
import { DoNotEnterIcon } from "prism";
import { computed, inject } from "vue";
import { useEditorStore } from "@/stores/editor_store";
import { rbgEditorApplyBackgroundColorV102 } from "kaleido-event-tracker";
import { PersistentStore } from "@/stores/persistent_store";

export interface ColorTilesProps {
  selectedImageState: PersistentStore;
  colors: string[];
}

const store = useEditorStore();

// native color picker tile is only selected if there is a color set that is on in the colorList
const nativeColorPickerSelected = computed(() => {
  if (props.colors.includes(props.selectedImageState?.selectedBackgroundColor)) return false;
  if (props.selectedImageState?.selectedBackgroundColor == "#ffffff") return false;
  if (props.selectedImageState?.selectedBackgroundColor) return true;
});

const props = defineProps<ColorTilesProps>();
const tempBackgroundColor = inject<{
  updateTempBackgroundColor: (string) => void;
}>("tempBackgroundColor");

const resetBackgroundImage = () => {
  props.selectedImageState.setSelectedBackgroundPhotoUrl(undefined);
  props.selectedImageState.setBackgroundBlurEnabled(false);
};

const setCurrentColor = (color: string) => {
  tempBackgroundColor.updateTempBackgroundColor(color);
  if (props.selectedImageState.selectedBackgroundPhotoUrl || props.selectedImageState.isBackgroundBlurEnabled) {
    props.selectedImageState.withSnapshot(() => {
      resetBackgroundImage();
    });
  }
};

const select = (color: string) => {
  tempBackgroundColor.updateTempBackgroundColor(null);
  props.selectedImageState.withSnapshot(() => {
    resetBackgroundImage();
    props.selectedImageState.setSelectedBackgroundColor(color);
  });

  rbgEditorApplyBackgroundColorV102({
    image_id: store.selectedImage.meta.id,
    color_value: color,
  });
};

const isSelected = (color) => {
  return props.selectedImageState.selectedBackgroundColor === color;
};
</script>

<style>
input[type="color"] {
  opacity: 0;
  display: block;
  width: 32px;
  height: 32px;
  border: none;
}
#color-picker-wrapper {
  float: left;
}
</style>
