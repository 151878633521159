class Split {
  static variant(experiment: string): string {
    const experiments = document.querySelector('meta[name="experiments"]')

    // If we can't parse the experiments, fall back to the "control" group
    // Note that here might not be a control group defined depending on the experiment
    try {
      const parsedExperiments = JSON.parse(experiments?.getAttribute("content") || "{}")
      return parsedExperiments.hasOwnProperty(experiment) ? parsedExperiments[experiment] : "control"
    } catch (_error) {
      return "control"
    }
  }
}

export default Split
