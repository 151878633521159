<template>
  <div
    class="editor-container flex justify-center items-center gap-6 mx-1 md:mx-6 relative"
    :class="editorWrapperClasses"
  >
    <div class="md:hidden z-20 fixed bottom-0 w-full">
      <!-- Panel manager for mobile -->
      <PanelManager
        :current-persistent-store="currentPersistentStore"
        :restore-original-result="restoreOriginalResult"
        :editor="editor"
        :toggle-panel="togglePanel"
        :stage="stage"
        :scale="initialScale"
      />
    </div>
    <!-- Start Result -->
    <div class="flex flex-col">
      <div class="relative clip">
        <div class="absolute z-10 w-full" style="top: 20px">
          <div class="flex shrink justify-center">
            <Toast v-show="toast">{{ toast }}</Toast>
          </div>
        </div>

        <div ref="cursor" class="cursor pointer-events-none fixed top-0 left-0" :style="cursorStyles">
          <svg :height="cursorDimensions.height" :width="cursorDimensions.width">
            <circle
              :cx="cursorDimensions.cx"
              :cy="cursorDimensions.cy"
              :r="cursorDimensions.r"
              :fill="store.drawSettings.color"
              stroke-width="0"
            ></circle>
          </svg>
        </div>

        <!-- Overlay in case of error -->
        <div
          v-if="hasError"
          class="hidden md:flex absolute top-0 left-0 w-full h-full bg-typo z-20 rounded-lg opacity-80"
        ></div>
        <div v-if="hasError" class="md:hidden"><Error /></div>

        <div
          ref="editor"
          v-if="!hasError"
          class="relative"
          :class="draggableCursorClasses"
          @wheel="handleMouseWheel"
          @mouseenter="handleEditorMouseEnter"
          @mouseleave="handleEditorMouseLeave"
          @mousemove="handleEditorMouseMove"
        >
          <div
            v-if="shouldDisplayPreviewPill && isHdAvailable"
            :data-tippy-content="I18n.t('image.scaled_preview_hd')"
            data-tippy-placement="bottom"
            class="inline-flex rounded-full !bg-secondary px-3 py-2 absolute top-4 left-4 z-50"
          >
            <span class="text-typo-secondary text-xs hover-tooltip w-full">{{ I18n.t("image.preview") }}</span>
          </div>
          <div
            v-if="shouldDisplayPreviewPill && !isHdAvailable"
            :data-tippy-content="I18n.t('image.scaled_preview')"
            data-tippy-placement="bottom"
            class="inline-flex rounded-full !bg-secondary px-3 py-2 absolute top-4 left-4 z-50"
          >
            <span class="text-typo-secondary text-xs hover-tooltip w-full">{{ I18n.t("image.preview") }}</span>
          </div>

          <ImageDragger
            ref="dragger"
            :value="sliderValue"
            @update="updateSliderValue"
            rounded="sm"
            class="pointer-events-none"
            :disabled="true"
            :checker-board-style="{ zIndex: -2 }"
          >
            <template #front>
              <v-stage
                ref="stage"
                :config="stageConfig"
                class="pointer-events-auto flex justify-center"
                :style="{ backgroundColor: tempBackgroundColor }"
              >
                <v-layer ref="backgroundLayer" :config="backgroundLayerConfig">
                  <v-image
                    v-if="props.currentPersistentStore?.selectedBackgroundPhotoUrl"
                    ref="backgroundPhoto"
                    :config="backgroundImageConfig"
                  />
                  <v-rect
                    v-else-if="props.currentPersistentStore?.selectedBackgroundColor"
                    :config="backgroundColorRectConfig"
                  />
                </v-layer>
                <v-layer ref="originalBackgroundLayer" :config="originalBackgroundLayerConfig">
                  <v-image :image="currentLayers?.originalBackgroundImage" :config="originalBackgroundImageConfig" />
                </v-layer>
                <v-layer ref="foregroundLayer">
                  <v-image :config="shadowLayerConfig" :image="currentLayers?.shadowImage" />
                  <v-image :config="semiTransparencyLayerConfig" :image="currentLayers?.semiTransparencyImage" />
                  <v-image :image="currentLayers?.foregroundImage" />
                </v-layer>
                <v-layer id="drawingLayer" ref="drawingLayer" :config="drawingLayerConfig">
                  <v-line v-for="line in store.lines" :config="lineConfig(line)"></v-line>
                </v-layer>
              </v-stage>
            </template>
            <template #back>
              <v-stage ref="stageOriginalBg" :config="stageConfig" class="flex justify-center">
                <v-layer>
                  <v-image :image="currentLayers?.originalBackgroundImage" :config="originalBackgroundImageConfig" />
                </v-layer>
              </v-stage>
            </template>
          </ImageDragger>
        </div>

        <!-- Stage for HD Images only - hidden in UI -->
        <div v-if="!hasError && (isProcessingHdDownload || loadHdStage)" class="hidden">
          <v-stage ref="stageHd" :config="stageConfigHd">
            <v-layer ref="backgroundLayerHd">
              <v-image
                v-if="getPersistentStore(hdStageImage)?.selectedBackgroundPhotoUrl"
                ref="backgroundPhotoHD"
                :config="backgroundImageConfigHD"
              />
              <v-rect
                v-else-if="getPersistentStore(hdStageImage)?.selectedBackgroundColor"
                :config="backgroundColorRectConfigHD"
              />
            </v-layer>
            <v-layer ref="foregroundLayerHd">
              <v-image :config="shadowLayerConfigHd" :image="layersForImage(hdStageImage)?.shadowImageHd" />
              <v-image
                v-if="isSemiTransparencyLayerVisible"
                :image="layersForImage(hdStageImage)?.semiTransparencyImageHd"
              />
              <v-image :image="layersForImage(hdStageImage)?.foregroundImageHd" />
            </v-layer>
          </v-stage>
        </div>
        <transition name="fade">
          <MagicOverlay v-show="isProcessing && !hasError" class="rounded-lg overflow-hidden" />
        </transition>

        <transition name="fade">
          <MagicOverlay v-show="store.isApplyingBackgroundChanges" class="rounded-lg overflow-hidden" />
        </transition>

        <Shine
          v-if="showShine"
          class="absolute top-0 left-0 text-brand -translate-x-8 -translate-y-8 hidden lg:block"
        />
      </div>

      <!-- Start bottom bar Desktop -->
      <div v-if="!hasError" class="hidden md:flex w-100">
        <div class="flex gap-4 items-center py-2 rounded-2xl w-100 justify-center">
          <!-- Zoom Buttons -->
          <div class="flex items-center gap-2 mr-2">
            <span>
              <IconButton
                variant="secondary"
                :disabled="!zoomable.canZoomOut.value"
                :title="I18n.t('editor.zoom_out')"
                @click="zoomable.zoomOut"
                ><MinusIcon
              /></IconButton>
              <IconButton
                variant="secondary"
                :disabled="!zoomable.canZoomIn.value"
                :title="I18n.t('editor.zoom_in')"
                @click="zoomable.zoomIn"
                ><PlusIcon
              /></IconButton>
            </span>
          </div>
          <!-- End Zoom Buttons -->
          <span class="text-secondary text-base h-8 w-[1px] rounded-full bg-secondary"></span>
          <!-- Toggle Compare -->
          <IconButton
            variant="secondary"
            :title="I18n.t('editor.hold_to_compare')"
            :disabled="isProcessing"
            @mousedown="toggleCompareBefore"
            @mouseup="toggleCompareAfter"
            @touchstart="toggleCompareBefore"
            @touchend="toggleCompareAfter"
            target-size="lg"
            ><CompareIcon
              :class="{
                'text-brand-typo': store.viewMode == ViewMode.Before && !isProcessing,
                'text-typo': store.viewMode == ViewMode.After && !isProcessing,
              }"
          /></IconButton>
          <!-- End Toggle Compare-->
          <span class="text-secondary text-base h-8 w-[1px] rounded-full bg-secondary"></span>
          <!-- Undo/Redo Buttons -->
          <div class="flex items-center gap-2 mr-2">
            <span>
              <IconButton
                variant="secondary"
                :disabled="!props.currentPersistentStore?.canUndo"
                :title="I18n.t('editor.undo')"
                @click="props.currentPersistentStore?.undo"
                ><UndoIcon
              /></IconButton>
              <IconButton
                variant="secondary"
                :disabled="!props.currentPersistentStore?.canRedo"
                :title="I18n.t('editor.redo')"
                @click="props.currentPersistentStore?.redo"
                ><RedoIcon
              /></IconButton>
            </span>
          </div>
          <!-- End Undo/Redo Buttons -->
        </div>
      </div>
      <!-- End bottom bar Desktop-->
    </div>
    <!-- End Result -->

    <!-- Start Sidebar Desktop -->
    <div ref="tools" id="side-panel" class="hidden md:flex flex-col gap-12" style="min-width: 20.5rem">
      <!-- Start ToolBox -->
      <div class="z-20 absolute top-2">
        <!-- Panel manager for desktop -->
        <PanelManager
          :current-persistent-store="currentPersistentStore"
          :restore-original-result="restoreOriginalResult"
          :editor="editor"
          :toggle-panel="togglePanel"
          :stage="stage"
          :scale="initialScale"
        />
      </div>
      <!-- End ToolBox -->

      <!-- Start Buttons -->
      <div v-if="enableUI" class="flex flex-col items-start gap-3 toolbox-btns">
        <ActionButtonPlaceholder v-if="isProcessing"></ActionButtonPlaceholder>
        <ActionButton v-else @click="togglePanel('addBackground')">
          <template #icon>
            <AddIcon
              v-if="
                !props.currentPersistentStore?.selectedBackgroundPhotoUrl &&
                !props.currentPersistentStore?.selectedBackgroundColor
              "
            />
            <span v-else class="w-full h-full" :style="backgroundActionButtonStyle" />
          </template>
          <template #title>{{ I18n.t(`editor.background`) }}</template>
        </ActionButton>
        <ActionButtonPlaceholder v-if="isProcessing"></ActionButtonPlaceholder>
        <span
          v-if="hasExceededMagicBrushIterations(store.selectedImage, refreshTippy)"
          class="hover-tooltip"
          :data-tippy-content="`${I18n.t('ai_brush.error.iterations_exceeded')}`"
          data-tippy-placement="bottom"
        >
          <ActionButton
            v-if="!isProcessing"
            :disabled="hasExceededMagicBrushIterations(store.selectedImage, refreshTippy)"
          >
            <template #icon>
              <BrushIcon />
            </template>
            <template #title>
              {{ abTestEraseRestoreLabel }}
            </template>
          </ActionButton>
        </span>
        <template v-else>
          <ActionButton
            v-if="!isProcessing"
            @click="togglePanel('eraseRestore')"
            :disabled="hasExceededMagicBrushIterations(store.selectedImage, refreshTippy)"
          >
            <template #icon>
              <BrushIcon />
            </template>
            <template #title>
              {{ abTestEraseRestoreLabel }}
            </template>
          </ActionButton>
        </template>
        <template v-if="Flipper.isEnabled('effects_panel')">
          <ActionButtonPlaceholder v-if="isProcessing"></ActionButtonPlaceholder>
          <ActionButton v-else @click="togglePanel('fx')">
            <template #icon>
              <FxIcon />
            </template>
            <template #title>{{ I18n.t(`editor.apply_effects`) }}</template>
          </ActionButton>
        </template>
        <ActionButtonPlaceholder v-if="isProcessing"></ActionButtonPlaceholder>
        <CanvaCta
          v-else
          :getDataURL="getDataURL"
          :stage="stage"
          :stageHd="getStageHd"
          :image="store.selectedImage"
          :reset-zoom="zoomable.resetZoom"
          :refresh-all-hd-layers="refreshAllHdLayers"
          :persistent-store="getPersistentStore(store.selectedImage)"
        />
      </div>
      <div v-if="enableUI" class="flex flex-col items-center gap-1">
        <ButtonPlaceholder v-if="isProcessing"></ButtonPlaceholder>
        <Button
          v-else
          full-width
          @click="
            () => {
              downloadPreview();
            }
          "
          :loading="isProcessingPreviewDownload"
          >{{ I18n.t("image.download") }}</Button
        >
        <div class="d-block d-md-inline text-typo-tertiary mb-1" v-if="!isProcessing">
          {{ store.selectedImage.meta.previewWidth }} &times; {{ store.selectedImage.meta.previewHeight }} px
          <span
            class="hover-tooltip"
            :data-tippy-content="`<strong>${I18n.t('image.good_quality')}</strong><br/>${I18n.t(
              'image.up_to_025_megapixels'
            )}`"
            data-tippy-placement="bottom"
          >
            <InfoSmallIcon> </InfoSmallIcon>
          </span>
        </div>
        <div v-else="isProcessing" class="mb-4">
          <div class="flex gap-1 items-center cursor-wait text-typo-tertiary">
            <div class="h-6 w-16 rounded-full !bg-secondary animate-pulse" />
            &times;
            <div class="h-6 w-16 rounded-full !bg-secondary animate-pulse" />
          </div>
        </div>

        <DownloadHdDialog
          :editor="editor"
          :is-processing="isProcessing"
          :is-processing-hd-download="isProcessingHdDownload"
          :is-processing-preview-download="isProcessingPreviewDownload"
          :enable-download-hd-button="enableDownloadHdButton"
          :credits="credits"
          :load-credits-and-update-preview="loadCreditsAndUpdatePreview"
          :download-hd="downloadHd"
          :is-ios-disabled="isImageTooLargeForIos"
        />
        <div v-if="isProcessing">
          <div class="flex gap-1 items-center cursor-wait text-typo-tertiary">
            <div class="h-6 w-16 rounded-full !bg-secondary animate-pulse" />
            &times;
            <div class="h-6 w-16 rounded-full !bg-secondary animate-pulse" />
          </div>
        </div>
        <div
          v-else="
            enableDownloadHdButton &&
            store.selectedImage.meta.hdWidth &&
            store.selectedImage.meta.hdHeight &&
            !isProcessing
          "
          class="d-block d-md-inline center text-typo-tertiary"
        >
          {{ store.selectedImage.meta.hdWidth }} &times; {{ store.selectedImage.meta.hdHeight }} px
          <span
            class="hover-tooltip"
            :data-tippy-content="`<strong>${I18n.t('image.best_quality')}</strong><br/>${I18n.t(
              'image.up_to_x_megapixels',
              { x: Configs.get('max_output_resolution_mp') }
            )}`"
            data-tippy-placement="bottom"
          >
            <InfoSmallIcon />
          </span>
        </div>
        <span v-if="!enableDownloadHdButton && !isProcessing" class="text-typo-tertiary">
          {{ I18n.t("image.not_available") }}
          <span
            class="hover-tooltip"
            :data-tippy-content="`${I18n.t('image.please_upload_higher_resolution')}`"
            data-tippy-placement="bottom"
          >
            <InfoSmallIcon />
          </span>
        </span>
      </div>
      <!-- End Buttons -->

      <!-- Start Error -->
      <div v-if="hasError" class="z-20 absolute top-2 w-[328px]">
        <Error />
      </div>
      <!-- End Error -->
    </div>
    <!-- End Sidebar Desktop -->

    <BottomBarMobile
      :stage="stage"
      :stageHd="getStageHd"
      :credits="credits"
      :editor="editor"
      :isProcessing="isProcessing"
      :isProcessingPreviewDownload="isProcessingPreviewDownload"
      :isProcessingHdDownload="isProcessingHdDownload"
      :hasError="hasError"
      :editorStore="store"
      :currentPersistentStore="props.currentPersistentStore"
      :backgroundActionButtonStyle="backgroundActionButtonStyle"
      :downloadPreview="downloadPreview"
      :downloadHd="downloadHd"
      :loadCreditsAndUpdatePreview="loadCreditsAndUpdatePreview"
      :refreshTippy="refreshTippy"
      :togglePanel="togglePanel"
      :getDataURL="getDataURL"
      :resetZoom="zoomable.resetZoom"
      :refreshAllHdLayers="refreshAllHdLayers"
    />
  </div>
  <TrustComponent ref="trustComponent" @enqueueWithToken="enqueueWithToken" style="position: absolute"></TrustComponent>
  <MissingForegroundDialog :current-persistent-store="currentPersistentStore" />
</template>

<script setup lang="ts">
import "@/src/i18n";
import { ref, computed, onMounted, onBeforeUnmount, inject, watch, nextTick, reactive, set, provide } from "vue";
import emitter from "@/modules/event_bus";

import {
  Shine,
  MagicOverlay,
  ActionButton,
  ActionButtonPlaceholder,
  Button,
  ButtonPlaceholder,
  ImageDragger,
  BrushIcon,
  AddIcon,
  Toast,
  InfoSmallIcon,
  IconButton,
  CompareIcon,
  RedoIcon,
  UndoIcon,
  PlusIcon,
  MinusIcon,
  FxIcon,
} from "prism";
import TrustComponent from "@/components/upload/trustcomponent.vue";
import CanvaCta from "@/components/canva_cta.vue";

import Error from "@/components/upload/error.vue";
import DownloadHdDialog from "@/components/prism/download_hd_dialog.vue";
import PanelManager from "./panel_manager.vue";

import {
  extractLayers,
  clearLayers,
  hasMagicBrushError,
  getMagicBrushError,
  ResultVariant,
  ProcessingError,
} from "@/modules/internal_api/image";
import type { Image } from "@/modules/internal_api/image";
import {
  ProcessingState,
  UploadState,
  originalUrl,
  hasExceededMagicBrushIterations,
} from "@/modules/internal_api/image";
import { downloadURI, downloadWithShareSheet, getNestedKey, releaseCanvas } from "@/modules/utils";

import { Panel, ViewMode, useEditorStore } from "@/stores/editor_store";

import { DrawAction } from "@/stores/line";
import type { Line, Point } from "@/stores/line";
import Client from "@/modules/internal_api/client";
import Routes from "@/modules/routes";
import Flipper from "@/modules/flipper";
import User from "@/modules/user";
import Configs from "@/modules/configs";
import tippy from "tippy.js";

import MissingForegroundDialog from "./missing_foreground_dialog.vue";

import * as StackBlur from "stackblur-canvas";
import { fetchImage, capitilizeString } from "@/modules/utils";
import { PersistentStore } from "@/stores/persistent_store";

import { rbgEditorAppliedBrushV100, rbgEditorFailedMagicBrushV100, rbgImageDownloadV100 } from "kaleido-event-tracker";

import Konva from "konva";
import { useZoomable } from "@/composables/zoomable";
import { useResizableCanvas } from "@/composables/resizable_canvas";
import BottomBarMobile from "./bottom_bar_mobile.vue";
import { useCheckIfIOSImageTooLarge } from "@/composables/check_ios_image_too_large";

import { CursorDimension, useBrushCursor } from "@/composables/brush_cursor";
import { BackgroundImage } from "@/modules/editor/background_image";
import { EditorStage } from "@/modules/editor/stage";
import Split from "@/modules/split";
Konva.hitOnDragEnabled = true;

interface EditorProps {
  persistentStores: Map<string, PersistentStore>;
  currentPersistentStore: PersistentStore;
}

const props = defineProps<EditorProps>();
const store = useEditorStore();
const resizableCanvas = useResizableCanvas();
const I18n = inject("I18n");
const workers = ref([]);
const toast = ref<String>(null);

// UI state
const isSemiTransparencyLayerVisible = ref<Boolean>(true);
const backgroundPhoto = ref<any>(undefined);
const backgroundPhotoHD = ref<any>(undefined);
const isImageTooLargeForIos = useCheckIfIOSImageTooLarge();

const enableDownloadHdButton = computed(() => {
  return store.selectedImage.meta.fullAvailable;
});

const abTestEraseRestoreLabel =
  Split.variant("edit_cutout_label") === "edit_cutout" ? I18n.t(`editor.edit_cutout`) : I18n.t(`editor.erase_restore`);

const editor = ref<HTMLElement>();
const cursor = ref<HTMLElement>();
let minScale;
let maxScale;

const isProcessing = computed(() => {
  return isPreviewProcessing.value || isMagicBrushPreviewProcessing.value;
});
const initialScale = ref<number | null>(1);
const shouldDisplayPreviewPill = ref<boolean>(false);
const calcShouldDisplayPreviewPill = () => {
  const isBrushing = store.openedPanel === "eraseRestore" || store.drawSettings.isEnabled;
  const isScaledCanvas = initialScale.value > 1;

  shouldDisplayPreviewPill.value = isScaledCanvas && !isBrushing;
};

watch([() => store.selectedImage?.meta?.id, () => store.openedPanel, () => store.drawSettings.isEnabled], () => {
  const scale = resizableCanvas.getScale();

  initialScale.value = scale;
  calcShouldDisplayPreviewPill();
});
const isHdAvailable = computed(() => store.selectedImage?.hdResult?.state === ProcessingState.Finished);

watch(
  () => store.selectedImage?.meta?.previewHeight,
  () => {
    const isSameImage = store.selectedImage?.meta?.id === initialSelectedImageValue?.meta?.id;
    const hasDimensionsChanged =
      store.selectedImage?.meta?.previewWidth != initialSelectedImageValue?.meta?.previewWidth;
    if (isSameImage && hasDimensionsChanged) {
      initialScale.value = resizableCanvas.getScale(true);
    }
  }
);

// Konva
// There are currently no exported types for these layers :(
// https://github.com/konvajs/vue-konva/issues/135
const stage = ref();
const stageOriginalBg = ref();

const foregroundLayer = ref();
const backgroundLayer = ref();
const originalBackgroundLayer = ref();
const drawingLayer = ref();
// Temporary background color is being used as a more performant preview of background color when we use color picker
const tempBackgroundColor = ref<string | null>(null);
const updateTempBackgroundColor = (color: string) => {
  tempBackgroundColor.value = color;
};
provide("tempBackgroundColor", { tempBackgroundColor, updateTempBackgroundColor });

const stageHd = ref();
const foregroundLayerHd = ref();
const backgroundLayerHd = ref();

const loadHdStage = ref(false);
const getStageHd = async () => {
  loadHdStage.value = true;
  await nextTick();
  return stageHd.value.getStage();
};

onMounted(async () => {
  store.drawSettings.isEnabled = false;
  store.openedPanel = null;

  // Note: We recalculate the scale here because some of the component are not mounted
  // and their dimensions are not pick up when calculating the scale.
  // Here, we can be sure that all the component like footer, side drawer are rendered so that we get an accurate scale.
  // This prevents the image to scale bigger than available space, hence preventing issues like horizontal scroll.
  const scale = resizableCanvas.getScale(true);
  initialScale.value = scale;

  zoomable.setDefaultZoomLevel();
  zoomable.applyZoom();
  await nextTick();
  minScale = stage.value.getStage().scaleX();
  maxScale = minScale * 4;

  if (store.selectedImage.previewResult.state === ProcessingState.Finished) {
    await refreshAllLayers();
    // fitStageIntoParentContainer();
    // revealResult(); // temporarily disabling revealResult on mounted to avoid a flash and reveal of the old image when uploading a new one. ideally there is a way to distinguish the case where an image is being uploaded and the case where we just visit the page.
  }

  refreshTippy();
  emitter.emit("editorMounted");

  window.addEventListener("pagehide", function (_event) {
    cleanup();
  });

  window.addEventListener("pageshow", function (_event) {
    window.location.reload();
  });

  hideNavBar();
});

const zoomable = useZoomable({
  stage: stage,
  stageOriginalBg: stageOriginalBg,
  initialScale: initialScale,
  isProcessing: isProcessing,
});

let brushCursor = useBrushCursor({ editor: editor.value, stage: stage.value });
watch(editor, () => {
  brushCursor = useBrushCursor({ editor: editor.value, stage: stage.value });
});

let editorStage = new EditorStage(stage.value, stageOriginalBg.value);
watch([stage, stageOriginalBg], () => {
  editorStage = new EditorStage(stage.value, stageOriginalBg.value);
});

const cleanup = () => {
  // This is mostly for iOS because it doesn't seem to clean up the canvases properly
  // It;s not a full fix but reduces the retained size from 1MB to around 400KB
  [
    foregroundLayer,
    backgroundLayer,
    originalBackgroundLayer,
    drawingLayer,
    foregroundLayerHd,
    backgroundLayerHd,
  ].forEach((layer) => {
    const stage = layer.value?.getStage();
    const canvas = stage?.getCanvas()?._canvas;
    releaseCanvas(canvas);
    stage?.destroyChildren();
  });

  const internalCanvases = [];

  const shapes = Konva.shapes;
  for (var key in shapes) {
    const shape = shapes[key];
    internalCanvases.push(shape?.parent?.canvas?._canvas);
    internalCanvases.push(shape?.parent?.hitCanvas?._canvas);
    internalCanvases.push(shape?.parent?.bufferCanvas?._canvas);
    internalCanvases.push(shape?.parent?.bufferHitCanvas?._canvas);
  }

  const internalStages = Konva.stages;
  internalStages.forEach((stage) => {
    internalCanvases.push(stage?.canvas?._canvas);
    internalCanvases.push(stage?.hitCanvas?._canvas);
    internalCanvases.push(stage?.bufferCanvas?._canvas);
    internalCanvases.push(stage?.bufferHitCanvas?._canvas);
  });

  internalCanvases.forEach((canvas) => {
    releaseCanvas(canvas);
  });

  stage.value?.getStage()?.destroyChildren();
  stageHd.value?.getStage()?.destroyChildren();

  stage.value?.getStage()?.destroy();
  stageHd.value?.getStage()?.destroy();
};

const hideNavBar = () => {
  // There is a navbar from desktop that has larger height which shows up underneath the current header.
  // So we need to make sure for inline editor this header is not displayed in mobile screens.
  const navEl = document.getElementById("navbar");
  navEl.classList.add("hidden");
  navEl.classList.add("md:block");
};

onBeforeUnmount(() => {
  cleanup();
  BackgroundImage.clearCache();
});

const isSpacing = ref<Boolean>(false);

const refreshTippy = () => {
  tippy("[data-tippy-content]", { allowHTML: true, interactive: true });
};

const shadowToggleEnabled = computed(() => {
  const shadowImage = currentLayers.value?.shadowImage;
  return !!shadowImage && shadowImage !== null;
});

provide("shadowToggle", shadowToggleEnabled);

document.onkeydown = function (e) {
  if ((e.key === "z" || e.key == "Z") && e.shiftKey && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    props.currentPersistentStore?.redo();
  } else if ((e.key === "z" || e.key == "Z") && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    props.currentPersistentStore?.undo();
  }
  if (e.key == "+" && !(e.ctrlKey || e.metaKey)) {
    if (zoomable.canZoomIn) {
      zoomable.zoomIn();
    }
    if (lastMouseEvent) updateCursorPosition(lastMouseEvent);
  }
  if (e.key == "-" && !(e.ctrlKey || e.metaKey)) {
    if (zoomable.canZoomOut) {
      zoomable.zoomOut();
    }
    if (lastMouseEvent) updateCursorPosition(lastMouseEvent);
  }
  if (e.key == " " || e.key == "Meta" || e.key == "Ctrl") {
    isSpacing.value = true;
  }
};

document.onkeyup = function (e) {
  if (e.key == " " || e.key == "Meta" || e.key == "Ctrl") {
    isSpacing.value = false;
  }
};

const handleEditorMouseMove = (e: MouseEvent) => {
  if (store.drawSettings.isEnabled) {
    updateCursorPosition(e);
  }
};

let lastMouseEvent: MouseEvent;
const updateCursorPosition = (e: MouseEvent) => {
  const pos = brushCursor.calculateCursorPosition(e, stage.value);
  if (store.isMagicBrushEnabled) {
    store.magicBrushCursorPosition = pos;
  } else {
    store.manualBrushCursorPosition = pos;
  }

  lastMouseEvent = e;
};

const handleEditorMouseEnter = (e: MouseEvent) => {
  store.isHoveringEditor = true;
};

const handleEditorMouseLeave = (e: MouseEvent) => {
  store.isHoveringEditor = false;
};

const editorWrapperClasses = computed(() => {
  return {
    "overflow-y-hidden": !hasError.value && store.isSwitchingImages,
  };
});

const displayCursor = ref(false);

watch([() => store.isMagicBrushEnabled, () => store.openedPanel], () => {
  resetCursorPositions();
  if (store.openedPanel === "eraseRestore") {
    toggleDisplayCursor();
  }
});

const resetCursorPositions = () => {
  brushCursor.resetCursorPositions();
};

const toggleDisplayCursor = () => {
  displayCursor.value = true;

  setTimeout(() => {
    displayCursor.value = false;
  }, 500);
};

const cursorStyles = computed(() => {
  const isHoveringRelevantElement = store.isHoveringEditor || store.isAdjustingBrushSize;
  const isVisible = isHoveringRelevantElement && store.drawSettings.isEnabled && !isSpacing.value;
  const opacity = isVisible || displayCursor.value ? 0.9 : 0;

  let cursorPosition = store.isMagicBrushEnabled ? store.magicBrushCursorPosition : store.manualBrushCursorPosition;
  if (cursorPosition?.x === 0) {
    cursorPosition = brushCursor.centralCursorPosition(stage.value);
  }

  const x = Math.floor(cursorPosition.x);
  const y = Math.floor(cursorPosition.y);

  return {
    opacity: opacity,
    transform: `translate(${x}px, ${y}px)`,
  };
});

const cursorDimensions = ref<CursorDimension>({});
watch(
  [
    () => props.currentPersistentStore?.zoomLevel,
    () => store.isMagicBrushEnabled,
    () => store.drawSettings.action,
    () => store.drawSettings.magicBrushSize,
    () => store.drawSettings.brushSize,
    () => store.openedPanel === "eraseRestore",
  ],
  () => {
    cursorDimensions.value = brushCursor.cursorDimensions(stage.value);
  },
  { immediate: true }
);

const draggableCursorClasses = computed(() => {
  const showDraggableCursor = isSpacing.value || middleMouseButtonDown.value || isTwoFingerPanning.value;

  return {
    "cursor-draggable": showDraggableCursor,
  };
});

const dragger = ref<InstanceType<typeof ImageDragger>>();
const sliderValue = ref<number>(0);

const revealResult = () => {
  dragger?.value?.transition(100, 0, 2000);
};

const hideResult = (image: Image) => {
  updateLayerRef(image, { foregroundImage: null });
  dragger?.value?.transition(0, 100, 1000);
};

const updateSliderValue = (value: number) => {
  sliderValue.value = value;
};

const revealResultFromCurrentPosition = () => {
  dragger?.value?.transition(sliderValue.value, 0, 300);
};

const hideResultFromCurrentPosition = () => {
  dragger?.value?.transition(sliderValue?.value, 100, 300);
};

const trustComponent = ref<InstanceType<typeof TrustComponent>>();

const credits = ref<number>(0);
const loadCreditsAndUpdatePreview = async () => {
  zoomable.resetZoom();
  refreshPreviewFromStage();
  const url = Routes.get("credits_info_url");
  fetch(url)
    .then((res) => res.json())
    .then((data) => {
      credits.value = data.credits;
    });
};

type SzeneSize = { width: number; height: number };
const actualSzeneSize = computed((): SzeneSize => {
  const image: Image | null = store.selectedImage;
  // Fall back to 16:9ish for placeholder image
  let size = {
    width: 614,
    height: 406,
  };

  if (image && image.meta.previewWidth && image.meta.previewHeight) {
    size = {
      width: image.meta.previewWidth,
      height: image.meta.previewHeight,
    };
  }

  return size;
});

let szeneSize = ref<SzeneSize>({ height: 0, width: 0 });
watch(
  [actualSzeneSize, store.selectedImage, initialScale],
  () => {
    const image: Image | null = store.selectedImage;
    let size = actualSzeneSize?.value;

    if (image && (resizableCanvas.isCanvasExtendable() || resizableCanvas.isCanvasShrinkable())) {
      size = resizableCanvas.resizedCanvasDimensions();
    }

    szeneSize.value = size;
    calcShouldDisplayPreviewPill();
  },
  { immediate: true }
);

const szeneSizeHD = computed((): SzeneSize => {
  return szeneSize.value;
});

const stageConfigHd = computed(() => {
  const size: SzeneSize = szeneSizeHD.value;

  return {
    width: size.width,
    height: size.height,
  };
});

const stageConfig = computed(() => {
  const size: SzeneSize = szeneSize.value;
  return {
    width: size.width,
    height: size.height,
    onMouseDown: handleMouseDown,
    onTouchStart: handleMouseDown,
    onMouseMove: handleMouseMove,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
  };
});

const originalBackgroundLayerConfig = computed(() => {
  const config = {
    visible: store.drawSettings.isEnabled && store.drawSettings.action === DrawAction.Restore,
    opacity: 0.5,
  };

  return config;
});

const backgroundLayerConfig = computed(() => {
  return {
    visible:
      !store.drawSettings.isEnabled || (store.drawSettings.isEnabled && store.drawSettings.action === DrawAction.Erase),
    opacity: store.isSwitchingImages ? 0 : 1,
  };
});

const shadowLayerConfig = computed(() => {
  return {
    visible: props.currentPersistentStore?.isShadowLayerVisible && !store.drawSettings.isEnabled,
    opacity: props.currentPersistentStore?.current.shadowOpacity / 100,
  };
});

const shadowLayerConfigHd = computed(() => {
  if (!hdStageImage.value) return null;
  const image = hdStageImage.value;

  return {
    visible: getPersistentStore(image)?.isShadowLayerVisible,
    opacity: props.currentPersistentStore?.current.shadowOpacity / 100,
  };
});

const semiTransparencyLayerConfig = computed(() => {
  return {
    visible: isSemiTransparencyLayerVisible.value,
  };
});

const backgroundColorRectConfig = computed(() => {
  const size = actualSzeneSize.value;

  return {
    fill: props.currentPersistentStore?.selectedBackgroundColor,
    width: size.width,
    height: size.height,
  };
});

const backgroundColorRectConfigHD = computed(() => {
  if (!hdStageImage.value) return null;
  const image = hdStageImage.value;

  const size = {
    width: image.meta.hdWidth,
    height: image.meta.hdHeight,
  };

  return {
    fill: getPersistentStore(image)?.selectedBackgroundColor,
    width: size.width,
    height: size.height,
  };
});

const backgroundActionButtonStyle = computed(() => {
  if (props.currentPersistentStore?.selectedBackgroundPhotoUrl) {
    return {
      "background-image": `url('${props.currentPersistentStore?.selectedBackgroundPhotoUrl}')`,
      "background-size": "cover",
    };
  } else {
    return {
      "background-color": props.currentPersistentStore?.selectedBackgroundColor,
    };
  }
});

const originalBackgroundImageConfig = computed(() => {
  const originalBackgroundImage = currentLayers.value?.originalBackgroundImage;
  if (!originalBackgroundImage) return null;

  const size: SzeneSize = actualSzeneSize.value;

  // A very small close to 0.001 random value is added to height/width of the which is barely visible to normal eyes.
  // This is to force trigger the Konva layer to trigger re-render of the layer.
  // When the height and width of the background image is unchanged when the image is changed, the layer is not rendered properly
  // To prevent this, we add a tiny buffer pixel so that it re-renders.
  // Any better fix is highly welcome.
  const config = {
    width: size.width + Math.random() * 0.001,
    height: size.height + Math.random() * 0.001,
    image: originalBackgroundImage,
  };

  return config;
});

const backgroundImageConfig = computed(() => {
  const backgroundImage = currentLayers.value?.backgroundImage;
  if (!backgroundImage) return null;

  const config = {
    width: calculateCoverSize.value.width,
    height: calculateCoverSize.value.height,
    x: calculateCoverSize.value.x,
    y: calculateCoverSize.value.y,
    image: backgroundImage,
  };

  return config;
});

const backgroundImageConfigHD = computed(() => {
  if (!hdStageImage.value) return null;

  const layers = layersForImage(hdStageImage.value);
  const backgroundImage = layers.backgroundImage;

  if (!backgroundImage) return null;

  const config = {
    width: calculateCoverSizeHD.value.width,
    height: calculateCoverSizeHD.value.height,
    x: calculateCoverSizeHD.value.x,
    y: calculateCoverSizeHD.value.y,
    image: backgroundImage,
  };

  return config;
});

// Calculate the position of the mouse cursor and its relateive position in the scaled stage
const getPointerPositions = (): { pointerPosition: Point; focusPoint: Point } => {
  const theStage = stage.value.getStage();
  const scale = theStage.scaleX();

  const pointerPosition: Point = theStage.getPointerPosition();
  const focusPoint: Point = {
    x: (pointerPosition.x - theStage.x()) / scale,
    y: (pointerPosition.y - theStage.y()) / scale,
  };

  return { pointerPosition, focusPoint };
};

const storePointerPositions = (pointerPosition: Point, focusPoint: Point) => {
  store.selectedImage.meta.last_focus_point = focusPoint;
  store.selectedImage.meta.last_pointer_position = pointerPosition;
  store.updateImage(store.selectedImage);
};

watch(
  () => props.currentPersistentStore?.zoomLevel,
  () => {
    // If the zooming is back to 100 ie. the normal zoom level, reset the last_focus_point and last_pointer_position
    // This because we want to have a default behavior of zoom ie. zoom from center when start zooming from the start.
    if (props.currentPersistentStore?.zoomLevel === 100) {
      delete store.selectedImage.meta?.last_focus_point;
      delete store.selectedImage.meta?.last_pointer_position;
      store.updateImage(store.selectedImage);
    }
  }
);

const applyZoom = () => {
  zoomable.applyZoom(() => {
    if (lastMouseEvent) updateCursorPosition(lastMouseEvent);
  });
};

const isTwoFingerPanning = ref<Boolean>(false);
const panningGestureClearTimer = ref<ReturnType<typeof setTimeout> | null>(null);
const handleMouseWheel = async (event: WheelEvent) => {
  event.preventDefault();
  event.stopPropagation();

  const { focusPoint, pointerPosition } = getPointerPositions();
  // We need to update the pointer positions here because if the user opts to other form of zooming
  //  we need to make sure it zooms fro this focus point and not from center.
  storePointerPositions(pointerPosition, focusPoint);

  const isZoomingIn = event.deltaY < 0;
  const isZoomingOut = event.deltaY > 0;

  // Special browser behavior for pinch to zoom detection
  const isUsingPinchToZoomGesture = event.ctrlKey || event.metaKey;

  if (isUsingPinchToZoomGesture) {
    isTwoFingerPanning.value = false;
    let zoomFactor = Math.abs(event.deltaY);

    // Speed up super slow zooms, usually happening on trackpads
    if (zoomFactor <= 20) {
      zoomFactor *= 1.5;
    }

    if (isZoomingIn) zoomable.zoomIn({ zoomFactor });
    if (isZoomingOut) zoomable.zoomOut({ zoomFactor });
    zoomable.repositionAfterFocusPointZoom(focusPoint, pointerPosition);
  } else {
    isTwoFingerPanning.value = true;
    const theStage = stage.value.getStage();
    const delta = {
      x: event.deltaX,
      y: event.deltaY,
    };

    let newPos = {
      x: theStage.x() - delta.x / theStage.scaleX(),
      y: theStage.y() - delta.y / theStage.scaleY(),
    };

    newPos = zoomable.clampPos(newPos, stage.value);
    editorStage.updateStages({ position: newPos });

    // Wheel events do not have a clear start and end, so we need to switch the flag ourselfs afer a delay
    clearTimeout(panningGestureClearTimer.value);
    panningGestureClearTimer.value = setTimeout(() => {
      isTwoFingerPanning.value = false;
    }, 200);
  }
};

const stageFitRequested = ref<Boolean>(false);
const fitStageIntoParentContainer = () => {
  if (!stage.value) return;
  if (stageFitRequested.value) return;
  stageFitRequested.value = true;

  const { width, height, scale } = editingAreaSize();
  editorStage.updateStages({ height, width, scale });

  applyZoom();
  stageFitRequested.value = false;
};

const tools = ref<HTMLElement>();
const editingAreaSize = () => {
  initialScale.value = resizableCanvas.getScale();

  const newSize = {
    width: szeneSize.value.width,
    height: szeneSize.value.height,
    scale: initialScale.value,
  };

  return newSize;
};

window.addEventListener("resize", () => {
  initialScale.value = resizableCanvas.getScale(true);
  fitStageIntoParentContainer();
});

watch(
  () => store.openedPanel,
  () => {
    initialScale.value = resizableCanvas.getScale(true);
    fitStageIntoParentContainer();
  }
);

// Drawing mode
const isDrawing = ref<Boolean>(false);
const magicMaskTimeout = ref<ReturnType<typeof setTimeout> | null>(null);

// Panning mode
const isPanning = ref<Boolean>(false);

const pointerPosition = () => {
  const theStage = stage.value.getStage();
  const pointerPosition = theStage.getRelativePointerPosition();
  return { x: pointerPosition.x, y: pointerPosition.y };
};

const MIDDLE_MOUSE_BUTTON = 1;
const LEFT_MOUSE_BUTTON = 0;
const RIGHT_MOUSE_BUTTON = 2;

const mouseDownEventStack = [];
const middleMouseButtonDown = ref<Boolean>(false);
const leftMouseButtonDown = ref<Boolean>(false);
const rightMouseButtonDown = ref<Boolean>(false);

const handleMouseDown = (event) => {
  middleMouseButtonDown.value = event.evt.button === MIDDLE_MOUSE_BUTTON;
  leftMouseButtonDown.value = event.evt.button === LEFT_MOUSE_BUTTON;
  rightMouseButtonDown.value = event.evt.button === RIGHT_MOUSE_BUTTON;

  mouseDownEventStack.push(event);

  // We only want to defer the handling of the mouse down event if the drawSettings is enabled ie. magic brush panel is open
  // This is because we want to identify whether or not the mouse down events is for pinch zooming or for drawing.
  const isTouchEvent = (event.evt || event)?.touches?.length >= 1;
  (isTouchEvent && store.drawSettings.isEnabled) ? setTimeout(handleMouseDownFromEventStack, 100) : handleMouseDownFromEventStack();
};

const handleMouseDownFromEventStack = () => {
  const eventHandler = (event) => {
    if (magicMaskTimeout.value) {
      clearTimeout(magicMaskTimeout.value);
    }

    if (!isMultiTouch(event) && store.drawSettings.isEnabled && !isSpacing.value && !middleMouseButtonDown.value) {
      isDrawing.value = true;
      const position: Point = pointerPosition();
      if (!position) return;
      store.startLine(position);
    } else {
      // Only pan if the zoom level is != 100 and isSpacing.value = true and the mouse button is left
      // or if the zoom level is > 100 and the mouse button is middle
      const isZoomed = props.currentPersistentStore?.zoomLevel !== 100;

      if (!isZoomed) return;
      if ((isSpacing.value && leftMouseButtonDown.value) || middleMouseButtonDown.value) {
        isPanning.value = true;
      }
    }
  };

  const latestEvent = mouseDownEventStack.pop();
  eventHandler(latestEvent);
  mouseDownEventStack.length = 0;
};

const isMultiTouch = (event) => {
  const realEvent = event.evt || event;
  return realEvent?.touches?.length === 2;
};

const handleTouchMove = (event) => {
  if (isDrawing.value) {
    handleBrush(event);
    return;
  }
  const realEvent = event.evt || event;

  realEvent.preventDefault();
  realEvent.stopPropagation();

  if (realEvent.touches.length === 1) {
    handleOneTouchMove(realEvent);
  } else if (realEvent.touches.length === 2) {
    handleTwoTouchMove(realEvent);
  }
};

const prevTouches = ref<Point[] | null>(null);
const handleTwoTouchMove = async (event) => {
  const theStage = stage.value.getStage();

  const stagePos = theStage.position();
  const scale = theStage.scaleX();

  const touches = theStage.getPointersPositions();
  const currentTouch1 = touches[0];
  const currentTouch2 = touches[1];

  if (!prevTouches.value) {
    prevTouches.value = [currentTouch1, currentTouch2];
    return;
  }

  // Panning
  const prevCenter = getCenter(prevTouches.value[0], prevTouches.value[1]);
  const currentCenter = getCenter(currentTouch1, currentTouch2);
  const localCurrentCenter = {
    x: (currentCenter.x - stagePos.x) / scale,
    y: (currentCenter.y - stagePos.y) / scale,
  };

  touchStartPos.value = currentCenter;

  // Zooming
  const prevDistance = getDistance(prevTouches.value[0], prevTouches.value[1]);
  const currentDistance = getDistance(currentTouch1, currentTouch2);

  const distance = Math.abs(currentDistance - prevDistance);

  const isZoomingIn = currentDistance > prevDistance;
  const zoomFactor = distance;

  if (isZoomingIn) {
    if (zoomable.canZoomIn) {
      zoomable.zoomIn({ zoomFactor });
      applyZoom();
    }
  } else {
    if (zoomable.canZoomOut) {
      zoomable.zoomOut({ zoomFactor });
      applyZoom();
    }
  }

  const newScale = theStage.scaleX();

  const delta = {
    x: currentCenter.x - prevCenter.x,
    y: currentCenter.y - prevCenter.y,
  };

  let newPos = {
    x: currentCenter.x - localCurrentCenter.x * newScale + delta.x,
    y: currentCenter.y - localCurrentCenter.y * newScale + delta.y,
  };

  newPos = zoomable.clampPos(newPos, stage.value);
  editorStage.updateStages({ position: newPos });

  prevTouches.value = [currentTouch1, currentTouch2];

  const { pointerPosition, focusPoint } = getPointerPositions();
  storePointerPositions(pointerPosition, focusPoint);
};

const handleTouchEnd = (event) => {
  prevTouches.value = null;
};

const getDistance = (p1: Point, p2: Point) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

const getCenter = (p1: Point, p2: Point): Point => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
};

const touchStartPos = ref<Point>({ x: 0, y: 0 });
const handleOneTouchMove = (event) => {
  if (props.currentPersistentStore?.zoomLevel === 100) return;

  const theStage = stage.value.getStage();
  const stagePosition = theStage.getRelativePointerPosition();

  const oldCenter = {
    x: stagePosition.x - theStage.x(),
    y: stagePosition.y - theStage.y(),
  };

  let movementX = 0;
  let movementY = 0;

  if (event.touches) {
    const touches = getRelativePointersPositions(theStage);
    const touch = touches[0];

    movementX = touch.x - touchStartPos.value.x || 0;
    movementY = touch.y - touchStartPos.value.y || 0;
    touchStartPos.value = touch;

    return;
  } else {
    movementX = event.movementX || 0;
    movementY = event.movementY || 0;
  }

  const newCenter = {
    x: oldCenter.x - movementX,
    y: oldCenter.y - movementY,
  };

  let pos = {
    x: stagePosition.x - newCenter.x,
    y: stagePosition.y - newCenter.y,
  };

  pos = zoomable.clampPos(pos, stage.value);
  editorStage.updateStages({ position: pos });

  const { pointerPosition, focusPoint } = getPointerPositions();
  storePointerPositions(pointerPosition, focusPoint);
};

const handleBrush = (event) => {
  const position: Point = pointerPosition();
  if (!position) return;
  store.addLinePoint(position);
};

const handleMouseMove = (event) => {
  if (isDrawing.value) {
    handleBrush(event);
    return;
  }

  if (isPanning.value) {
    var theEvent = event.evt || event; // konva event or javascript event
    if (theEvent) {
      handleOneTouchMove(theEvent);
    }
  }
};

const handleMouseUp = (event) => {
  leftMouseButtonDown.value = false;
  middleMouseButtonDown.value = false;
  rightMouseButtonDown.value = false;

  if (isDrawing.value) {
    isDrawing.value = false;

    const eventMode = store.isMagicBrushEnabled ? "magic" : "ordinary";
    const eventType = store.drawSettings.action === DrawAction.Erase ? "erase" : "restore";
    if (store.drawSettings.action === DrawAction.Erase) {
      window.track("Editor", "pixels_erased", "Pixels erased");
    } else {
      window.track("Editor", "pixels_restored", "Pixels restored");
    }
    rbgEditorAppliedBrushV100({
      image_id: store.selectedImage.meta.id,
      mode: eventMode,
      action_type: eventType,
      brush_size: store.isMagicBrushEnabled ? store.drawSettings.magicBrushSize : store.drawSettings.brushSize,
    });

    if (store.lines.length === 0) return;

    magicMaskTimeout.value = setTimeout(() => {
      enqueueMagicBrush();
    }, 500);

    window.reportSplit("cutout_used_erase", null, false);
  } else {
    // lastCenter = null;
    isPanning.value = false;
  }
};

let drawingLayerOpacity = ref<any>(0.7);

const enqueueMagicBrush = async () => {
  // Generate a random id for this iteration
  const iterationId = Math.random().toString(36).substring(5);

  const image = store.selectedImage;
  drawingLayerOpacity.value = 1.0;
  await nextTick();
  const theStage = stage.value.getStage();
  const drawingLayerStage = drawingLayer.value.getStage();
  const mask = getDataURL(image, theStage, drawingLayerStage);
  drawingLayerOpacity.value = 0.7;
  const maskBlob: Blob = await fetch(mask).then((r) => r.blob());

  let alphaBytes: Uint8Array;
  let colorBytes: Uint8Array;
  let alphaUrl: string;
  const currentPersistentStore = getPersistentStore(image);
  const variant = currentPersistentStore?.previewVariant;
  if (variant === ResultVariant.MagicBrushPreview) {
    // The last iteration might have had an error
    if (image.magicBrushPreviewResult.state == ProcessingState.Error) {
      alphaUrl = image.meta.ai_brush_iterations[image.meta.ai_brush_last_iteration].alpha;
    } else {
      if (!image.magicBrushPreviewResult?.layers?.foreground?.alphaBlob) {
        // refresh the foreground image if layers are not there anymore because the error on the previous iteration removed the property
        await refreshForegroundImage(variant, image);
      }
      alphaBytes = image.magicBrushPreviewResult?.layers?.foreground?.alphaBytes;
      colorBytes = image.magicBrushPreviewResult?.layers?.foreground?.colorBytes;
    }
  } else {
    if (!image.previewResult?.layers?.foreground?.alphaBlob) {
      // refresh the foreground image if layers are not there anymore because the error on the previous iteration removed the property
      await refreshForegroundImage(variant, image);
    }
    alphaBytes = image.previewResult?.layers?.foreground?.alphaBytes;
    colorBytes = image.previewResult?.layers?.foreground?.colorBytes;
  }

  const cachedState = JSON.parse(JSON.stringify(getPersistentStore(image)?.current));
  let updatedImagePromise: Promise<Image> = Client.enqueueMagicBrush(
    image,
    maskBlob,
    colorBytes,
    alphaBytes,
    alphaUrl,
    store.drawSettings.action,
    iterationId,
    store.isMagicBrushEnabled
  );
  image["magicBrushPreviewResult"].state = ProcessingState.Processing;
  store.updateImage(image);
  let updatedImage = await updatedImagePromise;

  // we deliberately don't check for errors here because this just initiates the AiBrushWorker and only sets the ai_brush_proccessing_started_at.
  // Actual data from the worker will be fetched and checked inside the poll
  updatedImage = await poll(updatedImage, ResultVariant.MagicBrushPreview);
  store.clearLines();

  if (updatedImage.magicBrushPreviewResult.state === ProcessingState.Finished) {
    // TODO: Now that we are updating changes to local currentPersistentStore,
    //   I'm not sure it will actually reflect the changes to the actual persistentStores

    currentPersistentStore?.selectMagicBrushVariant();
    currentPersistentStore?.cacheMagicBrush({ iterationId: iterationId });
    currentPersistentStore?.snapshotWithState(cachedState);
    currentPersistentStore?.persist();

    if (store.selectedImage.meta.id !== updatedImage.meta.id) {
      return;
      // Don't wanna process no more.
    }

    refreshPreviewFromStage();
  }
};

const handleProcessingError = (processingError: ProcessingError) => {
  if (store.openedPanel === "eraseRestore") {
    store.drawSettings.isEnabled = true;
  }

  let message = "";
  if (
    processingError.code == "other" &&
    (processingError.message == "no_foreground_detected" || processingError.message == "no_foreground_updates")
  ) {
    let translationId = "ai_brush.error." + processingError.message;
    translationId += store.drawSettings.action === DrawAction.Erase ? "_erase" : "_restore";
    message = I18n.t(translationId);
    rbgEditorFailedMagicBrushV100({ image_id: store.selectedImage.meta.id, error_message: message });
  } else if (
    processingError.code == "other" &&
    processingError.message == "sorry_something_went_wrong_please_try_again_later"
  ) {
    const translationId = "ai_brush.error." + processingError.message;
    message = I18n.t(translationId);
  } else {
    message = processingError.message;
  }

  showToast(message);
};

const showToast = (message) => {
  toast.value = message;
  setTimeout(() => {
    toast.value = null;
  }, 3000);
};

const drawingLayerConfig = computed(() => {
  return {
    visible: store.drawSettings.isEnabled,
    opacity: drawingLayerOpacity.value || 0.7,
  };
});

const defaultLineConfig = computed(() => {
  let size = store.isMagicBrushEnabled ? store.drawSettings.magicBrushSize : store.drawSettings.brushSize;

  return {
    stroke: store.drawSettings.color,
    strokeWidth: size,
    lineCap: "round",
    lineJoin: "round",
  };
});

const lineConfig = (line: Line) => {
  const points = line.points
    .map((point: Point) => {
      return [point.x, point.y];
    })
    .flat();

  return {
    points,
    ...defaultLineConfig.value,
  };
};

/**
 * refreshPreviewFromStage updates the selectedImage preview from the stage
 */
const refreshPreviewFromStage = () => {
  if (previewNeedsRefresh.value === false) {
    // console.log("Preview does not need refresh");
    return;
  }
  // console.log("Refreshing preview from stage");
  try {
    const image: Image = store.selectedImage; // no zoom for previews
    image.meta.livePreview = getDataURL(image, stage.value.getStage());
    store.updateImage(image);
  } catch (e) {
    return;
  }
};

const refreshLayer = async (
  variant: ResultVariant,
  layerName: string,
  resultRef: string,
  image: Image
): Promise<void> => {
  // console.log("Refreshing layer", layerName, "for variant", variant);
  return new Promise(async (resolve, reject) => {
    const relevantState = getNestedKey(image, `${variant}.state`);
    const isMagicBrushResult = variant === ResultVariant.MagicBrushPreview || variant === ResultVariant.MagicBrushHd;

    if (relevantState !== ProcessingState.Finished && !isMagicBrushResult) {
      return resolve();
    }

    const layers = getNestedKey(image, `${variant}.layers`);
    let updatedImage = image;
    if (!layers) {
      // console.log("No layers found, extracting them for image", image.meta.id, "and variant", variant);
      updatedImage = await extractLayers(image, variant, getPersistentStore(image));
      store.updateImage(updatedImage);
    }

    const img: HTMLImageElement = new Image();
    const layer = getNestedKey(updatedImage, `${variant}.layers.${layerName}`);
    if (!layer.blob) {
      // This image does not have a layer with this name (e.g. shadow or semiTransparency)
      // Consider this a success as there is nothing to update
      return resolve();
    }

    const blob = layer.blob;
    img.src = URL.createObjectURL(blob);
    img.crossOrigin = "anonymous";
    img.onload = () => {
      updateLayerRef(updatedImage, { [resultRef]: img });

      return resolve();
    };
  });
};

const refreshForegroundImage = async (variant: ResultVariant, image: Image): Promise<void> => {
  await nextTick();

  const showPreview =
    hasError.value ||
    image.previewResult.state === ProcessingState.Processing ||
    image.original.state !== UploadState.Finished;

  if (showPreview) {
    const img: HTMLImageElement = new Image();

    img.src = store.selectedImage.meta.preview;
    img.crossOrigin = "anonymous";
    img.width = actualSzeneSize.value.width;
    img.height = actualSzeneSize.value.height;
    img.onload = () => {
      updateLayerRef(image, { foregroundImage: img });
      fitStageIntoParentContainer();
      return Promise.resolve();
    };
  }

  return refreshLayer(variant, "foreground", "foregroundImage", image);
};

const refreshShadowImage = async (variant: ResultVariant, image: Image): Promise<void> => {
  return refreshLayer(variant, "shadow", "shadowImage", image);
};

const refreshSemitransparencyImage = async (variant: ResultVariant, image: Image): Promise<void> => {
  return refreshLayer(variant, "semiTransparency", "semiTransparencyImage", image);
};

const refreshOriginalBackgroundImage = async (): Promise<void> => {
  return new Promise((resolve) => {
    const image = store.selectedImage;
    if (image.original.state !== UploadState.Finished) {
      resolve();
      return;
    }

    const size: SzeneSize = szeneSize.value;
    const source = image.original.url;
    const img = new Image();
    img.src = source;
    img.crossOrigin = "anonymous";
    img.width = size.width;
    img.height = size.height;

    img.onload = () => {
      updateLayerRef(image, { originalBackgroundImage: img });
      resolve();
    };
  });
};

const refreshBackgroundImage = async (image: Image): Promise<void> => {
  const persistentStore = getPersistentStore(image);
  const callbackFn = (backgroundImage: HTMLImageElement) => {
    updateLayerRef(image, { backgroundImage });
  };

  const beforeApplyingBackgroundImage = () => {
    if (persistentStore?.isBackgroundBlurEnabled) {
      store.isApplyingBackgroundChanges = true;
      persistentStore.setSelectedBackgroundLoading(true);
    }
  };
  const afterApplyingBackgroundImage = () => {
    store.isApplyingBackgroundChanges = false;
    persistentStore.setSelectedBackgroundLoading(false);
  };
  const onError = () => {
    store.isApplyingBackgroundChanges = false;
    persistentStore.setSelectedBackgroundLoading(false);
  };

  return BackgroundImage.updateBackgroundImage(persistentStore, callbackFn, {
    force: false,
    afterApplyingBackgroundImage,
    beforeApplyingBackgroundImage,
    onError,
  });
};

const layerRefreshRequestedBy = ref<string | null>(null);
const resetLayerRefreshRequestedBy = () => {
  // Only the selected image can reset the layerRefreshRequestedBy ref
  if (layerRefreshRequestedBy.value === store.selectedImage.meta.id) {
    layerRefreshRequestedBy.value = null;
  }
};
const refreshAllLayers = async (force: boolean = false): Promise<void> => {
  // We do not refresh the layers if the layer refresh was requested by some other image that is not currently selected.
  //  unless it's forced or no images had set this ref yet ie. when it's null.
  if (!(layerRefreshRequestedBy.value === store.selectedImageId || layerRefreshRequestedBy.value === null || force)) {
    return;
  }

  const image = store.selectedImage;
  image.meta.refreshingLivePreview = true;
  store.updateImage(image);
  layerRefreshRequestedBy.value = store.selectedImage.meta.id;

  await Promise.allSettled([refreshRemoteLayers(image), refreshLocalLayers(image)]);
  refreshPreviewFromStage();

  image.meta.refreshingLivePreview = false;
  store.updateImage(image);
  resetLayerRefreshRequestedBy();
};

const refreshRemoteLayers = async (image: Image): Promise<void> => {
  // console.log("Refreshing remote layers");
  updateLayerRef(image, {
    foregroundImage: null,
    shadowImage: null,
    semiTransparencyImage: null,
    originalBackgroundImage: null,
  });

  // make sure changes are drawn
  await nextTick();
  foregroundLayer.value?.getNode()?.batchDraw();

  const variant: ResultVariant = getPersistentStore(image)?.previewVariant;

  // Refresh foreground image first so that the layers are extracted only once
  // Otherwise the shadow and semi transparency layers would extract the layers from the zip file again
  await refreshForegroundImage(variant, image);

  await nextTick();
  fitStageIntoParentContainer();

  const promises = [refreshShadowImage(variant, image), refreshSemitransparencyImage(variant, image)];
  await Promise.allSettled(promises);
};

const refreshLocalLayers = async (image: Image): Promise<void> => {
  const promises = [];
  if (getPersistentStore(image)?.selectedBackgroundPhotoUrl) {
    promises.push(refreshBackgroundImage(image));
  }

  promises.push(refreshOriginalBackgroundImage());
  await Promise.allSettled(promises);
};

type ImageRefs = {
  foregroundImage?: HTMLImageElement | null;
  shadowImage?: HTMLImageElement | null;
  semiTransparencyImage?: HTMLImageElement | null;
  backgroundImage?: HTMLImageElement | null;
  originalBackgroundImage?: HTMLImageElement | null;
  foregroundImageHd?: HTMLImageElement | null;
  shadowImageHd?: HTMLImageElement | null;
  semiTransparencyImageHd?: HTMLImageElement | null;
};

let layerRef: { [key: string]: ImageRefs } = reactive({});

const updateLayerRef = (image: Image, value: ImageRefs) => {
  const existingRefs = layerRef[image.meta.id] || reactive({});
  for (const key in value) {
    Reflect.set(existingRefs, key, value[key]);
  }
  Reflect.set(layerRef, image.meta.id, existingRefs);
};

const currentLayers = computed(() => {
  return layerRef?.[store.selectedImage?.meta.id];
});

const layersForImage = (image: Image) => {
  if (!image) return null;

  return layerRef?.[image?.meta.id];
};

watch(
  () => currentLayers.value?.backgroundImage,
  async (value) => {
    if (!value) return;
    await nextTick();
    refreshPreviewFromStage();
  },
  { deep: true }
);

watch(
  () => store.viewMode,
  () => {
    if (store.viewMode == ViewMode.After) {
      revealResultFromCurrentPosition();
    }
    if (store.viewMode == ViewMode.Before) {
      hideResultFromCurrentPosition();
    }
  }
);

watch(
  () => store.images,
  () => {
    emitter.emit("imagesChanged", store.images.length);
  }
);

const previewNeedsRefresh = ref(true);

/**
 *  initialSelectedImageValue holds the value of selected image object that represents its value at the very beginning
 *    for eg: when the selectedImage contains preview details that are locally calulcated
 *    and the values from servers hasn't arrived yet.
 */
let initialSelectedImageValue: Image = null;

store.$onAction(async ({ name, after, args }) => {
  if (name === "selectImage") {
    const image = args[0] as Image;
    if (image?.meta?.id === store.selectedImage?.meta?.id) {
      return;
    }
    store.isSwitchingImages = true;
    previewNeedsRefresh.value = false;
    store.openedPanel = null;
    store.drawSettings.isEnabled = false;

    updateLayerRef(image, { backgroundImage: null });

    after(async () => {
      initialSelectedImageValue = store.selectedImage;

      try {
        layerRefreshRequestedBy.value = store.selectedImage.meta.id;
        await refreshAllLayers();

        zoomable.zoom({ animate: true });
        previewNeedsRefresh.value = true;
        refreshPreviewFromStage();
        fitStageIntoParentContainer();
        loadHdStage.value = false;
      } catch (e) {
      } finally {
        localStorage.setItem("lastSelectedImageId", image.meta.id);
        previewNeedsRefresh.value = true;
        resetLayerRefreshRequestedBy();
        store.isSwitchingImages = false;
      }
    });
  }
});

const hdStageImage = ref<Image | null>(null);
const refreshAllHdLayers = async (image: Image): Promise<void> => {
  updateLayerRef(image, {
    foregroundImageHd: null,
    shadowImageHd: null,
    semiTransparencyImageHd: null,
  });

  const variant: ResultVariant = props.currentPersistentStore?.hdVariant;

  // Refresh foreground image first so that the layers are extracted only once
  // Otherwise the shadow and semi transparency layers would extract the layers from the zip file again
  await refreshForegroundImageHd(variant, image);
  await Promise.allSettled([refreshShadowImageHd(variant, image), refreshSemitransparencyImageHd(variant, image)]);

  hdStageImage.value = image;
};

const refreshForegroundImageHd = async (variant: ResultVariant, image: Image): Promise<void> => {
  return refreshLayer(variant, "foreground", "foregroundImageHd", image);
};

const refreshShadowImageHd = async (variant: ResultVariant, image: Image): Promise<void> => {
  return refreshLayer(variant, "shadow", "shadowImageHd", image);
};

const refreshSemitransparencyImageHd = async (variant: ResultVariant, image: Image): Promise<void> => {
  return refreshLayer(variant, "semiTransparency", "semiTransparencyImageHd", image);
};

const isComparing = ref(false);
const toggleCompareBefore = (): void => {
  store.viewMode = ViewMode.Before;
  isComparing.value = true;
};

const toggleCompareAfter = (): void => {
  store.viewMode = ViewMode.After;
  isComparing.value = false;
};

const togglePanel = async (id: Panel) => {
  if (isProcessingHdDownload.value && downloadHDQueue[0]?.meta.id === store.selectedImage.meta.id) return;

  zoomable.setDefaultZoomLevel();
  applyZoom();
  zoomable.repositionAfterCenterZoom();

  store.togglePanel(id);

  if (store.openedPanel === "eraseRestore") {
    window.reportSplit("cutout_opened_panel", null, false);
    store.drawSettings.isEnabled = true;
  }

  if (store.openedPanel === id) {
    revealResultFromCurrentPosition();
  } else {
    store.drawSettings.isEnabled = false;
    await nextTick();
    refreshPreviewFromStage();
    refreshTippy();
  }
};

const restoreOriginalResult = (): void => {
  props.currentPersistentStore?.reset();
};

const toggleShadow = (): void => {
  const isVisible = props.currentPersistentStore?.isShadowLayerVisible;

  props.currentPersistentStore?.withSnapshot(() => {
    props.currentPersistentStore?.setShadowLayerVisible(!isVisible);
  });
};

// Downloads
const isProcessingPreviewDownload = ref(false);
const downloadPreview = async (force_share: boolean = false) => {
  const image: Image = store.selectedImage;
  if (image.previewResult.state !== ProcessingState.Finished) {
    return;
  }

  isProcessingPreviewDownload.value = true;

  window.track("Images", "download_from_editor_sd", "Download from Editor SD");
  window.track("Images", "download_from_editor", "Download from Editor");

  zoomable.setDefaultZoomLevel();
  applyZoom();
  zoomable.repositionAfterCenterZoom();
  const resolution = "standard";
  rbgImageDownloadV100({ image_id: image.meta.id, resolution: resolution, download_location: "inline_editor" });
  zoomable.setDefaultZoomLevel();
  applyZoom();
  zoomable.repositionAfterCenterZoom();

  const dataUrl = getDataURL(image, stage.value.getStage());
  const filename = image.previewResult.name + ".png";

  if (force_share) {
    await downloadWithShareSheet(image, dataUrl, filename, resolution);
  } else {
    await platformSpecificDownload(image, dataUrl, filename, resolution);
  }

  isProcessingPreviewDownload.value = false;
};

const platformSpecificDownload = async (
  image: Image,
  dataUrl: string,
  filename: string,
  resolution: "standard" | "high-definition"
) => {
  const isUsingMobileApp = User.mobileApp();
  const isUsingIOs = User.isUsingIOs();
  const isusingIOs17OrLater = User.iOsVersionIsAtLeast("17");

  if (isUsingMobileApp) {
    await window.mobile_app.downloadBase64(filename, dataUrl);
  } else if (isUsingIOs && isusingIOs17OrLater) {
    await downloadWithShareSheet(image, dataUrl, filename, resolution);
  } else {
    await downloadURI(dataUrl, filename);
  }
};

const timeouts: Map<string, any> = new Map();

const getDataURL = (image: Image, stage: any, element: any = undefined): string => {
  if (!element) element = stage; // use stage as element if not provided

  let oldScale = stage.scaleX();
  stage.scale({ x: 1, y: 1 });
  let oldX = stage.x();
  let oldY = stage.y();
  stage.position({ x: 0, y: 0 });
  let dataURL = element.toDataURL({ x: 0, y: 0, width: image.meta.previewWidth, height: image.meta.previewHeight });
  stage.position({ x: oldX, y: oldY });
  stage.scale({ x: oldScale, y: oldScale });
  return dataURL;
};

const poll = async (image: Image, variant: ResultVariant): Promise<Image> => {
  const stateKey: string = `${variant}.state`;
  const nextPollKey: string = `${variant}.nextFetchIn`;
  const timeoutKey: string = `${variant}.${image.meta.id}.timeout`;

  // console.log("Start polling for image", image.meta.id, "and variant", variant);
  const executePoll = async (image, resolve, reject): Promise<void> => {
    const updatedImage: Image = await Client.getImage(image.meta.id);

    const isMagicBrushVariant = [ResultVariant.MagicBrushPreview].includes(variant);

    // console.log("Received updated image", updatedImage.meta.id, "with state", getNestedKey(updatedImage, stateKey), "and next poll in", getNestedKey(updatedImage, nextPollKey), "ms");
    if (isMagicBrushVariant && hasMagicBrushError(updatedImage)) {
      const processingError: ProcessingError = getMagicBrushError(updatedImage);
      store.clearLines();
      handleProcessingError(processingError);
      store.selectedImage.magicBrushPreviewResult.state = ProcessingState.Error;
      return;
    }

    store.updateImage(updatedImage);
    const state = getNestedKey(updatedImage, stateKey) as ProcessingState;
    const isFinished = [ProcessingState.Finished, ProcessingState.Error].includes(state);

    if (isFinished === true) {
      clearTimeout(timeouts.get(timeoutKey));
      timeouts.delete(timeoutKey);
      return resolve(updatedImage);
    } else {
      const timeout = setTimeout(async () => {
        executePoll(updatedImage, resolve, reject);
      }, getNestedKey(updatedImage, nextPollKey));

      timeouts.set(timeoutKey, timeout);
    }
  };

  return new Promise((resolve, reject) => {
    executePoll(image, resolve, reject);
  });
};

const isProcessingHdDownload = ref(false);
let downloadHDQueue: Image[] = [];
const downloadHd = async (closeDialog?: () => void) => {
  if (closeDialog) closeDialog();
  const image: Image = store.selectedImage;
  queueImageForHDDownload(image);
};

const queueImageForHDDownload = (image: Image) => {
  downloadHDQueue.push(image);
  processHDQueue();
};

const processHDQueue = async (): Promise<void> => {
  if (downloadHDQueue.length === 0) return;
  let image = downloadHDQueue[0];

  // TODO: keep individual processing state for each image to allow multiple downloads at the same time
  isProcessingHdDownload.value = true;
  const variant: ResultVariant = getPersistentStore(image)?.hdVariant;

  let downloadFilename = "";
  if (variant === ResultVariant.Hd) {
    if (image.hdResult.state !== ProcessingState.Finished) {
      image = await Client.processHd(image);

      if (image.hdResult.state === ProcessingState.Error) {
        isProcessingHdDownload.value = false;
        // TODO: move this error out of the Ai Brush namespace
        showToast(I18n.t("ai_brush.error.unknown_error"));
        return;
      }
      store.updateImage(image);
      image = await poll(image, ResultVariant.Hd);
      emitter.emit("purchase");
    }

    if (image.hdResult.state !== ProcessingState.Finished) {
      isProcessingHdDownload.value = false;
      showToast(I18n.t("ai_brush.error.unknown_error"));
      return;
    }

    downloadFilename = image.hdResult.name;
  } else {
    image = await Client.enqueueMagicBrushHd(image, getPersistentStore(image));

    if (image.magicBrushHdResult.state === ProcessingState.Error) {
      isProcessingHdDownload.value = false;
      showToast(I18n.t("ai_brush.error.unknown_error"));
      return;
    }
    store.updateImage(image);
    image = await poll(image, ResultVariant.MagicBrushHd);

    if (image.magicBrushHdResult.state !== ProcessingState.Finished) {
      isProcessingHdDownload.value = false;
      showToast(I18n.t("ai_brush.error.unknown_error"));
      return;
    }

    downloadFilename = image.magicBrushHdResult.name;
  }

  await refreshAllHdLayers(image);
  const stage = stageHd.value.getStage();
  const dataUrl = stage.toDataURL({ x: 0, y: 0, width: image.meta.hdWidth, height: image.meta.hdHeight });

  window.track("Images", "download_from_editor_hd", "Download from Editor HD");
  window.track("Images", "download_from_editor", "Download from Editor");
  rbgImageDownloadV100({
    image_id: image.meta.id,
    resolution: "high-definition",
    download_location: "inline_editor",
  });

  const filename = downloadFilename + ".png";
  await platformSpecificDownload(image, dataUrl, filename, "high-definition");

  downloadHDQueue = downloadHDQueue.slice(1);
  isProcessingHdDownload.value = false;
  processHDQueue();
};

//Display checks
const showShine = computed(() => {
  return store.openedPanel !== null && !hasError.value && !isProcessing.value;
});

const isPreviewProcessing = computed(() => {
  return store.selectedImage?.previewResult?.state === ProcessingState.Processing;
});

watch(
  () => isPreviewProcessing.value,
  async () => {
    await nextTick();
    refreshTippy();
  }
);

const isMagicBrushPreviewProcessing = computed(
  () => store.selectedImage?.magicBrushPreviewResult?.state === ProcessingState.Processing
);

const hasError = computed(() => {
  const image = store.selectedImage;
  if (!image) return false;

  const originalCheck = image.original.state === UploadState.Error;
  const previewCheck = image.previewResult.state === ProcessingState.Error;

  return originalCheck || previewCheck;
});

const enableUI = computed(() => {
  return !hasError.value && store.openedPanel === null;
});

const imageWidth = computed(() => {
  switch (store.selectedImage?.meta?.orientation) {
    case "landscape":
      return 614;
    case "portrait":
      return 408;
    case "square":
      return 500;
    default:
      return 614;
  }
});

watch(
  () => props.currentPersistentStore?.selectedBackgroundColor,
  async (selectedBackgroundColor) => {
    if (selectedBackgroundColor && props.currentPersistentStore?.selectedBackgroundPhotoUrl)
      props.currentPersistentStore?.setSelectedBackgroundPhotoUrl(undefined);
    if (selectedBackgroundColor !== undefined) {
      await nextTick();
      refreshPreviewFromStage();
    }
  },
  { deep: true }
);

watch(
  () => props.currentPersistentStore?.selectedBackgroundPhotoUrl,
  async (selectedBackgroundPhotoUrl) => {
    if (selectedBackgroundPhotoUrl && props.currentPersistentStore?.selectedBackgroundColor)
      props.currentPersistentStore?.setSelectedBackgroundColor(undefined);
    if (selectedBackgroundPhotoUrl !== undefined) {
      await refreshBackgroundImage(store.selectedImage);
    }
  },
  { deep: true }
);

watch(
  () => props.currentPersistentStore?.blurRadius,
  async () => {
    if (props.currentPersistentStore?.selectedBackgroundPhotoUrl) {
      await refreshBackgroundImage(store.selectedImage);
    }
  },
  { deep: true }
);

watch(
  () => props.currentPersistentStore?.isBackgroundBlurEnabled,
  async () => {
    const isBackgroundBlurEnabled = props.currentPersistentStore?.isBackgroundBlurEnabled;
    const hasBackgroundPhoto = !!props.currentPersistentStore?.selectedBackgroundPhotoUrl;
    const isBackgroundAddedByBlur = props.currentPersistentStore?.isBackgroundAddedByBlur;

    if (hasBackgroundPhoto && !isBackgroundAddedByBlur) {
      await refreshBackgroundImage(store.selectedImage);
    } else if (isBackgroundBlurEnabled && !hasBackgroundPhoto) {
      props.currentPersistentStore?.setSelectedBackgroundColor(undefined);
      props.currentPersistentStore?.setSelectedBackgroundPhotoUrl(originalUrl(store.selectedImage));
      props.currentPersistentStore?.setIsBackgroundAddedByBlur(true);
    } else if (!isBackgroundBlurEnabled && isBackgroundAddedByBlur) {
      props.currentPersistentStore?.setSelectedBackgroundPhotoUrl(undefined);
    }
  },
  { deep: true }
);

watch(
  () => props.currentPersistentStore?.previewVariant,
  async () => {
    if (layerRefreshRequestedBy.value) return;

    const image = store.selectedImage;
    await refreshAllLayers();
    // if (image.hdResult?.state === ProcessingState.Finished) {
    //   await refreshAllHdLayers();
    // }
  },
  { deep: true }
);

watch(
  () => props.currentPersistentStore?.magicBrushCache?.iterationId,
  async () => {
    if (!props.currentPersistentStore?.magicBrushCache?.iterationId) return;

    const iterationId = store.selectedImage.meta?.ai_brush_last_iteration;
    const iterationHistory = store.selectedImage?.meta.ai_brush_iterations;
    const iteration = iterationHistory?.[iterationId];

    if (!iteration) return;

    const image = clearLayers(store.selectedImage, ResultVariant.MagicBrushPreview);
    store.updateImage(image);
    await refreshAllLayers();
    // if (iteration.hd) {
    //   await refreshAllHdLayers();
    // }
  },
  { deep: true }
);

// const hasImagePreviewErrored = (store: EditorStoreSGA) => {
//   if (store.selectedImage.previewResult.state === "error") return true;

//   return false;
// };

// const showMobileDownloadHdIcon = () =>
//   !!store.selectedImage && !hasImagePreviewErrored(store) && !!store.selectedImage.meta.preview;

const calculateCoverSize = computed(() => {
  const backgroundImage = currentLayers.value?.backgroundImage;
  const szene = actualSzeneSize.value;
  if (!backgroundImage) return { width: szene.width, height: szene.height };

  const scale = Math.max(szene.width / backgroundImage.width, szene.height / backgroundImage.height);
  const x = szene.width / 2 - (backgroundImage.width / 2) * scale;
  const y = szene.height / 2 - (backgroundImage.height / 2) * scale;

  return { width: backgroundImage.width * scale, height: backgroundImage.height * scale, x, y };
});

const calculateCoverSizeHD = computed(() => {
  const image = store.selectedImage;
  const backgroundImage = currentLayers.value?.backgroundImage;

  const szene = {
    width: image.meta.hdWidth,
    height: image.meta.hdHeight,
  };

  if (!backgroundImage) return { width: szene.width, height: szene.height };

  const scale = Math.max(szene.width / backgroundImage.width, szene.height / backgroundImage.height);
  const x = szene.width / 2 - (backgroundImage.width / 2) * scale;
  const y = szene.height / 2 - (backgroundImage.height / 2) * scale;

  return { width: backgroundImage.width * scale, height: backgroundImage.height * scale, x, y };
});

const getPersistentStore = (image: Image): PersistentStore | null => {
  if (!image) return null;
  return props.persistentStores.get(image.meta.id);
};

// inspired by https://github.com/konvajs/konva/blob/f7c8b81dd16f28e607f2034232736f715a0edd51/src/Node.ts#L1156
const getRelativePointersPositions = (node: any): Array<Point> => {
  if (!node) {
    return [];
  }

  const stage = node.getStage();
  // get pointer (say mouse or touch) position
  let pos = stage.getPointersPositions();
  if (!pos) {
    return [];
  }

  let transform = node.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();

  let newPos = [];
  pos.forEach((p) => {
    // now we can find relative point
    newPos.push(transform.point(p));
  });

  return newPos;
};

const enqueueWithToken = async (image) => {
  emit("enqueueWithToken", image);
};

const emit = defineEmits<{
  (e: "enqueueWithToken", image: Image): void;
}>();

// Expose to parent
defineExpose({
  revealResult,
  hideResult,
  refreshForegroundImage,
  refreshAllLayers,
  refreshRemoteLayers,
  trustComponent,
  handleMouseDown,
  handleMouseUp,
  handleMouseMove,
  toggleCompareBefore,
  toggleCompareAfter,
});
</script>

<style>
.konvajs-content > canvas:first-child {
  z-index: -1;
}

.checkerboard {
  z-index: -2;
}

.checkerboard + div {
  z-index: 1;
}

.prism-imagedragger input[type="range"]::-webkit-slider-thumb {
  position: relative;
  z-index: 1;
}

.cursor {
  z-index: 30;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

*[style*="display: none"] {
  display: none !important;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.hover-tooltip > svg {
  display: inline;
}

.cursor-draggable {
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: url(images/grab.cur); /* fallback: Chrome 1-21, Firefox 1.5-26, Safari 4+, IE, Edge 12-14, Android 2.1-4.4.4 */
  cursor: grab; /* W3C standards syntax, all modern browser */
}

.cursor-draggable:active {
  cursor: url(images/grabbing.cur);
  cursor: grabbing;
}

.clip {
  clip-path: inset(0 0 0 0);

  @media (min-width: 460px) {
    & {
      clip-path: none;
    }
  }
}

.toolbox-btns {
  @media (min-width: 992px) {
    min-width: 328px;
  }
}

/* TODO: implement proper fix */
/* #mobile\=buttons > button:nth-child(1) > span.font-bold.text-2xs.sm\:text-sm.w-full.text-center.sm\:text-left.font-body.\!no-underline.\!text-typo.transition.ease-in-out.group-hover\:\!text-typo-secondary.sm\:no-touch-hover\:group-hover\:translate-x-0\.5 */
#mobile-buttons button span.text-2xs {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
